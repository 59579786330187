export class SearchFilterModel  {
    status?: string[] | string;
    items?: number | string;
    page?: number | string;
    start?: string;
    end?: string;
    isChecked?: string[] | string;
    without_archived?: boolean |string;
    searchValue?: string;
    searchFields?: string[] | string;
    collaborators?: string;
    employees?: string;
    renewals_filter?: string[] | string;
    anomalies_filter?:string[] | string;
    
}